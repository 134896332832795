import React, { useState } from 'react';
import styled from 'styled-components';

// Styled Components
const ProductsContainer = styled.div`
  padding: 40px;
  background-color: #f8f8f8;

  @media (max-width: 768px) {
    padding: 20px; /* Reduced padding for smaller screens */
  }
`;

const Title = styled.h2`
  color: #c49863;
  font-family: 'Pacifico', cursive;
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 28px; /* Reduced font size for smaller screens */
    margin-bottom: 20px;
  }
`;

const Subtitle = styled.h3`
  color: #2F9ABF;
  font-size: 36px;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 28px; /* Reduced font size for smaller screens */
    margin-bottom: 20px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  gap: 15px;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  background-color: ${({ active }) => (active ? '#2F9ABF' : '#fff')};
  color: ${({ active }) => (active ? '#fff' : '#c49863')};
  border: 2px solid #2F9ABF;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff6f91;
    color: white;
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    font-size: 14px; /* Adjusted font size for smaller screens */
    padding: 8px 16px; /* Reduced padding for smaller screens */
  }
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Stacked layout for smaller screens */
  }
`;

const ProductCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  padding: 30px; 
  transition: transform 0.3s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    padding: 20px; /* Reduced padding for smaller screens */
  }
`;

const ProductImage = styled.img`
  width: 100%; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px;
  margin-bottom: 15px;
`;

const ProductTitle = styled.h3`
  color: #c49863;
  font-size: 24px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px; /* Adjusted font size for smaller screens */
  }
`;

const ProductDescription = styled.p`
  color: #2F9ABF;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjusted font size for smaller screens */
  }
`;

const ProductPrice = styled.p`
  color: #2F9ABF;
  font-family: 'Arial', sans-serif;
  font-size: 18px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px; /* Adjusted font size for smaller screens */
  }
`;

const Notification = styled.div`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 8px; /* Reduced padding for smaller screens */
    font-size: 14px; /* Adjusted font size for smaller screens */
  }
`;


const Products = ({ addToCart }) => {
  const [notification, setNotification] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  const handleAddToCart = (product) => {
    addToCart(product);
    setNotification(`${product.title} has been added to the cart!`);

    setTimeout(() => {
      setNotification('');
    }, 3000);
  };

  const handleFilterChange = (category) => {
    setSelectedCategory(category);
  };

  const products = [
    { id: 1, image: '/pro1.jpeg', title: 'DOGGO AS RAPUNZEL CAKE', category: 'cakes', description: 'Unravel the magic one bite at a time with this whimsical Rapunzel cake!' },
    { id: 2, image: '/pro2.jpeg', title: 'BONE APPETIT CAKE', category: 'cakes', description: "A cake full of your pooch's favourites." },
    { id: 3, image: '/pro3.jpeg', title: 'COOKIE JAR CAKE', category: 'cakes', description: "Nothing is better than some delicious cookies with your droolicious pup inside." },
    { id: 4, image: '/pro4.jpeg', title: 'AYE AYE CAPTAIN', category: 'cakes', description: "Set sail for adventure with this swashbuckling pirate cake; treasure awaits in every bite!" },
    { id: 5, image: '/pro5.jpeg', title: 'MY TREASURES CAKE', category: 'cakes', description: "Every dog's little treasured things in a cake." },
    { id: 6, image: '/pro6.jpeg', title: 'WONDERLAND CAKE', category: 'cakess', description: "A rainbow of sweetness topped with a macaron dream; every bite is pure joy!" },
    { id: 7, image: '/pro7.jpeg', title: 'FAIRYTALE CAKE', category: 'cakes', description: "Pastel colours with rainbows and butterflies make it a cake straight out of Pinterest." },
    { id: 8, image: '/pro8.jpeg', title: 'WORLD OF BALLS', category: 'cakes', description: "A cake perfect for all the ball lovers out there." },
    { id: 9, image: '/pro9.jpeg', title: 'MERMAID CAKE', category: 'cakes', description: "Make a splash with this sparkly cake, perfect for your little mermaid." },
    { id: 10, image: '/pro10.jpeg', title: 'BALL MUNCH CAKE', category: 'cakes', description: "This tennis balls cake is a smash hit!!" },
    { id: 11, image: '/pro11.jpeg', title: 'LOVE IN THE AIR', category: 'cakes', description: "Cake perfect for a lovey-dovey occasion." },
    { id: 12, image: '/pro12.jpeg', title: 'NUMBER CAKE', category: 'cakes', description: "Will love all the toppings like macarons, bones, chocolate bars." },
    { id: 13, image: '/pro13.jpeg', title: '3D DOGGO CAKE', category: 'cakes', description: "" },
    { id: 14, image: '/pro14.jpeg', title: 'STARY NIGHT CAKE', category: 'cakes', description: 'Starry night with hues of blues, a big bright moon, and your pup; nothing can get more perfect.' },
    { id: 15, image: '/pro15.jpeg', title: 'LLAMA CAKE', category: 'cakes', description: "A cake full of colours; llama tell you this cake is too cute to resist." },
    { id: 16, image: '/pro16.jpeg', title: 'TREAT BOX CAKE', category: 'cakes', description: "Every doggo's dream to dive in the jar full of treats. A cake that's too fun to explore for your pup." },
    { id: 17, image: '/pro17.jpeg', title: 'OH CRABB!! CAKE', category: 'cakes', description: "A cake with beachy bliss and crabby cuteness, perfect for a beach pooch." },
    { id: 18, image: '/pro26.jpeg', title: 'BIRTHDAY PUPCAKES', category: 'cupcakes', description: "" },
    { id: 19, image: '/pro27.jpeg', title: 'MERMAID THEME PUPCAKES', category: 'cupcakes', description: "" },
    { id: 20, image: '/pro28.jpeg', title: 'BEACH THEME PUPCAKES', category: 'cupcakes', description: "" },
    { id: 21, image: '/pro29.jpeg', title: 'BATMAN THEME PUPCAKES', category: 'cupcakes', description: "" },
    { id: 22, image: '/pro30.jpeg', title: 'BALL THEME PUPCAKES', category: 'cupcakes', description: "" },
    { id: 23, image: '/pro31.jpeg', title: 'MINI PUPCAKES', category: 'cupcakes', description: "" },
    { id: 24, image: '/pro32.jpeg', title: 'DOG FACE THEME PUPCAKES', category: 'cupcakes', description: "" },
    { id: 25, image: '/pro33.jpeg', title: 'MINI PUPCAKES', category: 'cupcakes', description: "" },
    { id: 26, image: '/pro34.jpeg', title: 'BEE THEMED PUPCAKES', category: 'cupcakes', description: "" },
    { id: 27, image: '/pro35.jpeg', title: 'STARRY NIGHT THEME PUPCAKES', category: 'cupcakes', description: "" },
    { id: 28, image: '/pro36.jpeg', title: 'UNICORN THEMED CUPCAKES', category: 'cupcakes', description: "" },
    { id: 29, image: '/pro37.jpeg', title: 'BIRTHDAY THEME FROSTED COOKIES', category: 'treats', description: "" },
    { id: 30, image: '/pro38.jpeg', title: 'BIRTHDAY THEME FROSTED COOKIES', category: 'treats', description: "" },
    { id: 31, image: '/pro39.jpeg', title: 'I WOOF U FROSTED COOKIES', category: 'treats', description: "" },
    { id: 32, image: '/pro40.jpeg', title: 'CHICKEN SWIRL ANCHOVIES', category: 'treats', description: "" },
    { id: 33, image: '/pro41.jpeg', title: 'CHICKEN JERKY', category: 'treats', description: "" },
    { id: 34, image: '/pro42.jpeg', title: 'BLUEBERRY CHICKEN CHIPS', category: 'treats', description: "" },
    { id: 35, image: '/pro43.jpeg', title: 'BISCUITS', category: 'treats', description: "" },
    { id: 36, image: '/pro44.jpeg', title: 'MINI HEART SHAPE PIZZA', category: 'snacks', description: "" },
    { id: 37, image: '/pro45.jpeg', title: 'CHICKEN AND SAUSAGE PIZZA', category: 'snacks', description: "" },
    { id: 38, image: '/pro46.jpeg', title: 'CHICKEN AND EGG PIZZA', category: 'snacks', description: "" },
    { id: 39, image: '/pro47.jpeg', title: 'MEATBALLS', category: 'snacks', description: "" },
    { id: 40, image: '/pro48.jpeg', title: 'PANEER PIZZA', category: 'snacks', description: "" },
    { id: 41, image: '/pro49.jpeg', title: 'BUGS THEME PUPCAKE', category: 'cupcakes', description: "" },
    { id: 42, image: '/pro59.jpeg', title: 'HAKUNA MATATA THEME PUPCAKES', category: 'cupcakes', description: "" },
    { id: 43, image: '/pro61.jpeg', title: 'BOMB HAMPER', category: 'hampers', description: "Celebrate the festive season with a little extra indulgence! This hamper is specially curated for your pooch. Every treat is hand-crafted and baked to perfection." },
    { id: 44, image: '/pro60.jpeg', title: 'PATAKA HAMPER', category: 'hampers', description: "Celebrate the festive season with a little extra indulgence! This hamper is specially curated for your pooch. Every treat is hand-crafted and baked to perfection." },
    { id: 45, image: '/pro62.jpeg', title: 'DHAMAKA HAMPER', category: 'hampers', description: "Celebrate the festive season with a little extra indulgence! This hamper is specially curated for your pooch. Every treat is hand-crafted and baked to perfection." },
];


  const filteredProducts = selectedCategory === 'all' ? products : products.filter(product => product.category === selectedCategory);

  return (
    <ProductsContainer>
      <Title>Our Products</Title>
      <Subtitle>We offer customizable options</Subtitle>
      {notification && <Notification>{notification}</Notification>}
      <FilterContainer>
        <FilterButton active={selectedCategory === 'all'} onClick={() => handleFilterChange('all')}>All</FilterButton>
        <FilterButton active={selectedCategory === 'cakes'} onClick={() => handleFilterChange('cakes')}>Cakes</FilterButton>
        <FilterButton active={selectedCategory === 'cupcakes'} onClick={() => handleFilterChange('cupcakes')}>Cupcakes</FilterButton>
        <FilterButton active={selectedCategory === 'snacks'} onClick={() => handleFilterChange('snacks')}>Snacks</FilterButton>
        <FilterButton active={selectedCategory === 'treats'} onClick={() => handleFilterChange('treats')}>Treats</FilterButton>
        <FilterButton active={selectedCategory === 'hampers'} onClick={() => handleFilterChange('hampers')}>Festive Hampers</FilterButton>
      </FilterContainer>
      <ProductsGrid>
        {filteredProducts.map((product) => (
          <ProductCard key={product.id}>
            <ProductImage src={product.image} alt={product.title} />
            <ProductTitle>{product.title}</ProductTitle>
            <ProductDescription>{product.description}</ProductDescription>
            {/* <AddToCartButton onClick={() => handleAddToCart(product)}>Add to Cart</AddToCartButton> */}
          </ProductCard>
        ))}
      </ProductsGrid>
    </ProductsContainer>
  );
};

export default Products;
