import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase'; // Adjust the path as needed
import { Link } from 'react-router-dom';

// Styled Components
const LoginWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url('./login background.webp') no-repeat center center/cover;
  padding: 20px; /* Added padding for smaller screens */
`;

const LoginContainer = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  text-align: center;

  @media (max-width: 480px) {
    padding: 30px; /* Reduced padding for smaller screens */
  }
`;

const Title = styled.h2`
  color: #ff6f8b;
  margin-bottom: 20px;
  font-size: 24px;

  @media (max-width: 480px) {
    font-size: 20px; /* Adjusted font size for smaller screens */
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormField = styled.input`
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  width: 100%;
  font-size: 16px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 480px) {
    padding: 12px; /* Reduced padding for smaller screens */
    font-size: 14px; /* Adjusted font size for smaller screens */
  }
`;

const SubmitButton = styled.button`
  padding: 15px 30px;
  background-color: #ff6f8b;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;

  &:hover {
    background-color: #ff5671;
  }

  @media (max-width: 480px) {
    padding: 12px 20px; /* Adjusted padding for smaller screens */
    font-size: 14px; /* Adjusted font size for smaller screens */
  }
`;

const ErrorMessage = styled.div`
  color: #d9534f;
  margin-bottom: 15px;
  font-size: 14px; /* Adjusted font size for better readability on small screens */
`;

const RegistrationLink = styled(Link)`
  margin-top: 15px;
  color: #ff85a2;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 480px) {
    font-size: 14px; /* Adjusted font size for smaller screens */
  }
`;


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/'); // Redirect to homepage after successful login
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <LoginWrapper>
      <LoginContainer>
        <Title>Login to DroolingTails</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Form onSubmit={handleLogin}>
          <FormField
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <FormField
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <SubmitButton type="submit">Login</SubmitButton>
        </Form>

        <RegistrationLink to="/register">
        Don't have an account? Register here
      </RegistrationLink>
      </LoginContainer>
    </LoginWrapper>
  );
};

export default Login;
