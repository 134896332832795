import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const CartContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
`;

const CartTitle = styled.h2`
  font-family: 'Pacifico', cursive;
  color: #c49863;
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
`;

const CartItem = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ItemImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 20px;
`;

const ItemDetails = styled.div`
  flex: 1;
`;

const CartItemTitle = styled.span`
  display: block;
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-bottom: 5px;
`;

const CartItemDescription = styled.p`
  font-size: 14px;
  color: #666;
`;

const CartItemPrice = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #ff85a2;
  padding: 20px;
`;

const QuantityControls = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const QuantityButton = styled.button`
  background-color: #ff85a2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff6f91;
  }
`;

const QuantityDisplay = styled.span`
  font-size: 16px;
  color: #333;
`;

const RemoveButton = styled.button`
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff3333;
  }
`;

const TotalPrice = styled.div`
  text-align: right;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
`;

const CheckoutButton = styled.button`
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #45a049;
  }
`;

const Cart = ({ cartItems, updateQuantity, removeFromCart, user }) => {

  const navigate = useNavigate();
  const totalPrice = cartItems.reduce((total, item) => {
    const itemPrice = item.price || 0;
    const itemQuantity = item.quantity || 1;
    return total + itemPrice * itemQuantity;
  }, 0);

  const handleCheckout = () => {
    if (user) {
      // Redirect to checkout page if the user is logged in
      navigate('/checkout');
    } else {
      // Redirect to login page if the user is not logged in
      navigate('/login');
    }
  };

  return (
    <CartContainer>
      <CartTitle>Your Cart</CartTitle>
      {cartItems.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        <>
          {cartItems.map((item, index) => (
            <CartItem key={index}>
              <ItemImage src={item.image} alt={item.title} />
              <ItemDetails>
                <CartItemTitle>{item.title}</CartItemTitle>
                <CartItemDescription>{item.description}</CartItemDescription>
                <QuantityControls>
                  <QuantityButton onClick={() => updateQuantity(index, item.quantity - 1)}>-</QuantityButton>
                  <QuantityDisplay>{item.quantity}</QuantityDisplay>
                  <QuantityButton onClick={() => updateQuantity(index, item.quantity + 1)}>+</QuantityButton>
                </QuantityControls>
              </ItemDetails>
              <CartItemPrice>₹{(item.price * item.quantity).toFixed(2)}</CartItemPrice>
              <RemoveButton onClick={() => removeFromCart(index)}>Remove</RemoveButton>
            </CartItem>
          ))}
          <TotalPrice>Total: ₹{totalPrice.toFixed(2)}</TotalPrice>
          <CheckoutButton onClick={handleCheckout}>Checkout</CheckoutButton>
        </>
      )}
    </CartContainer>
  );
};

export default Cart;
