import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const auth = getAuth();

const RegistrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url('./login background.webp') no-repeat center center/cover;
  padding: 20px; /* Added padding for smaller screens */
`;

const RegistrationForm = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%; /* Allow the form to be responsive */
  max-width: 400px; /* Set a maximum width */
`;

const FormField = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  width: 100%; /* Make the input field responsive */

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #2F9ABF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #2589A6;
  }

  @media (max-width: 768px) {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-bottom: 15px;
  font-size: 14px; /* Adjust font size for better readability */

  @media (max-width: 768px) {
    font-size: 12px; /* Further reduce font size on smaller screens */
  }
`;


const Registration = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    fullName: '',
    phoneNumber: '',
    address: '',
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, confirmPassword } = formData;

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
        await createUserWithEmailAndPassword(auth, email, password);
        navigate('/login'); // Redirect to login page after successful registration
      } catch (error) {
        setError(error.message);
      }
    };

  return (
    <RegistrationContainer>
      <RegistrationForm onSubmit={handleSubmit}>
        <h2>Register</h2>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <FormField
          type="text"
          name="fullName"
          placeholder="Full Name"
          value={formData.fullName}
          onChange={handleInputChange}
          required
        />
        <FormField
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <FormField
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          required
        />
        <FormField
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          required
        />
        <FormField
          type="tel"
          name="phoneNumber"
          placeholder="Phone Number"
          value={formData.phoneNumber}
          onChange={handleInputChange}
          required
        />
        <FormField
          type="text"
          name="address"
          placeholder="Address"
          value={formData.address}
          onChange={handleInputChange}
          required
        />
        <SubmitButton type="submit">Register</SubmitButton>
      </RegistrationForm>
    </RegistrationContainer>
  );
};

export default Registration;
