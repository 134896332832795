import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const CheckoutContainer = styled.div`
  margin: 50px auto;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  padding: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

const Button = styled.button`
  background-color: #ff85a2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff6f91;
  }
`;

const Checkout = ({ cartItems, clearCart, user }) => {
  const [address, setAddress] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('credit-card');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      console.log("User is not logged in, redirecting to login page.");
      navigate('/login');
      return;
    }

    const order = {
      username: user.displayName || 'Guest',
      email: user.email,
      address,
      paymentMethod,
      items: cartItems,
      createdAt: new Date(),
    };

    try {
      await addDoc(collection(db, 'orders'), order);
      clearCart();
      navigate('/OrderConfirmation');
    } catch (error) {
      console.error('Error placing order:', error);
      alert('There was an issue placing your order. Please try again.');
    }
  };

  return (
    <CheckoutContainer>
      <h2>Checkout</h2>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="address">Address</Label>
          <Input
            id="address"
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="payment-method">Payment Method</Label>
          <Select
            id="payment-method"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            <option value="credit-card">Paytm</option>
            <option value="paypal">Google Pay</option>
            <option value="bank-transfer">Bank Transfer</option>
          </Select>
        </FormGroup>
        <Button type="submit">Place Order</Button>
      </form>
    </CheckoutContainer>
  );
};

export default Checkout;
