import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const CarouselContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CarouselWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentIndex, slidesToShow }) => `translateX(-${currentIndex * (100 / slidesToShow)}%)`};
`;

const Slide = styled.div`
  min-width: ${({ slidesToShow }) => 100 / slidesToShow}%; /* Adjust width based on number of slides to show */
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  background-color: #fff0f6;
  border-radius: 10px;

  @media (max-width: 768px) {
    min-width: 50%; /* Show 2 slides on medium screens */
  }

  @media (max-width: 480px) {
    min-width: 100%; /* Show 1 slide on small screens */
  }
`;

const ProductImage = styled.img`
  width: 100%;
  height: 125%;
  max-height: 500px;
  border-radius: 10px;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 18px;
  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  ${({ direction }) => direction === 'left' && `
    left: 10px; 
  `}
  ${({ direction }) => direction === 'right' && `
    right: 10px; 
  `}
`;

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);
  const slides = [
    { id: 1, image: '/c1.jpeg'},
    { id: 2, image: '/c2.jpeg'},
    { id: 3, image: '/c3.jpeg' },
    { id: 4, image: '/c4.jpeg'},
    { id: 5, image: '/c5.jpeg'},
    { id: 6, image: '/c6.jpeg'}
  ];

  const handleResize = () => {
    if (window.innerWidth <= 480) {
      setSlidesToShow(1); // 1 slide on small screens
    } else if (window.innerWidth <= 768) {
      setSlidesToShow(2); // 2 slides on medium screens
    } else {
      setSlidesToShow(3); // 3 slides on large screens
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? slides.length - slidesToShow : prevIndex - 1
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === slides.length - slidesToShow ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 2000);

    return () => clearInterval(interval);
  }, [slidesToShow]);

  return (
    <CarouselContainer>
      <NavButton direction="left" onClick={goToPrevious}>{'<'}</NavButton>
      <CarouselWrapper currentIndex={currentIndex} slidesToShow={slidesToShow}>
        {slides.map(slide => (
          <Slide key={slide.id} slidesToShow={slidesToShow}>
            <ProductImage src={slide.image} alt={`Slide ${slide.id}`} />
          </Slide>
        ))}
      </CarouselWrapper>
      <NavButton direction="right" onClick={goToNext}>{'>'}</NavButton>
    </CarouselContainer>
  );
};

export default Carousel;
