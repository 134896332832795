import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ConfirmationContainer = styled.div`
  margin: 50px auto;
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const ConfirmationMessage = styled.h2`
  color: #ff85a2;
  margin-bottom: 20px;
`;

const OrderDetails = styled.p`
  color: #333;
  font-size: 16px;
  margin-bottom: 20px;
`;

const HomeLink = styled(Link)`
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ff85a2;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff6f91;
  }
`;

const OrderConfirmation = () => {
  return (
    <ConfirmationContainer>
      <ConfirmationMessage>Thank you for your order!</ConfirmationMessage>
      <OrderDetails>Your order has been successfully placed. You will be contacted by Us shortly.</OrderDetails>
      <HomeLink to="/">Continue Shopping</HomeLink>
    </ConfirmationContainer>
  );
};

export default OrderConfirmation;
