import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase'; // Import the Firebase auth

const HeaderContainer = styled.header`
  background-color: #ffecf1;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 100%;
  box-sizing: border-box;
  margin: 0;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 480px) {
    padding: 10px 15px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Ensure the title and menu button span full width on smaller screens */

  @media (min-width: 481px) {
    justify-content: flex-start; /* Align title and nav for larger screens */
  }
`;

const Title = styled.h1`
  color: #c49863;
  font-family: 'Comic Sans MS', sans-serif;
  margin: 0;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 0;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')}; // Toggle display based on menu state
  }
`;

const NavLink = styled(Link)`
  margin: 0 15px;
  color: #c49863;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 480px) {
    margin: 10px 0;
  }
`;

const MenuButton = styled.button`
  display: none;
  background: none;
  border: none;
  color: #c49863;
  font-size: 2rem;
  cursor: pointer;

  @media (max-width: 480px) {
    display: block;
  }
`;

const Header = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // Handle user logout
  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/'); // Redirect to home after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  // Toggle menu for small screens
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeaderContainer>
      <TitleContainer>
        <Title>
          <Link to="/" style={{ color: '#c49863', textDecoration: 'none' }}>
            DroolingTails
          </Link>
        </Title>
        <MenuButton onClick={toggleMenu}>
          {isOpen ? '✕' : '☰'} {/* X icon for closing and menu icon for opening */}
        </MenuButton>
      </TitleContainer>
      <Nav isOpen={isOpen}>
        <NavLink to="/" onClick={() => setIsOpen(false)}>Home</NavLink>
        <NavLink to="/products" onClick={() => setIsOpen(false)}>Products</NavLink>
        <NavLink to="/about" onClick={() => setIsOpen(false)}>About</NavLink>
        <NavLink to="/contact" onClick={() => setIsOpen(false)}>Contact</NavLink>
        {user ? (
          <NavLink to="#" onClick={() => { handleLogout(); setIsOpen(false); }}>Logout</NavLink>
        ) : (
          <NavLink to="/login" onClick={() => setIsOpen(false)}>Login</NavLink>
        )}
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
