import React from 'react';
import styled from 'styled-components';
import Carousel from '../components/Carousel';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeContainer = styled.div`
  padding: 20px;
  text-align: center;
  color: #2F9ABF;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const BreadSliceContainer = styled.div`
  width: 100%;
  max-width: 700px; /* Cap the maximum width */
  height: auto; /* Adjust height based on content */
  background-color: #f8f8f8; 
  border-radius: 100px 100px 0 0; 
  overflow: hidden;
  position: relative;
  margin: 0 auto; /* Center container */
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  border: none; /* Ensure no borders are applied */
  outline: none; /* Remove any outlines */

  @media (max-width: 768px) {
    height: 500px; /* Adjusted height for tablets and iPad portrait */
  }

  @media (max-width: 480px) {
    height: 400px; /* Adjusted height for smaller phones */
  }

  @media (max-width: 414px) {
    height: 420px; /* Adjust height for iPhone Plump portrait */
  }

  @media (max-width: 375px) {
    height: 380px; /* Adjust height for iPhone portrait */
  }

  @media (max-width: 734px) and (orientation: landscape) {
    height: 600px; /* Adjust for iPhone landscape */
  }

  @media (min-width: 1024px) and (orientation: landscape) {
    height: 700px; /* Adjust for iPad landscape */
  }
`;

const Logo = styled.img`
  width: 110%; /* Maintain full width */
  height: 90%; /* Maintain aspect ratio */
  margin: -100px -50px;

  @media (max-width: 768px) {
    width: 110%; 
    margin: -75px -40px; /* Shift left more for tablets (portrait) */
  }

  @media (max-width: 480px) {
    width: 110%; 
    margin: -80px -90px; /* Shift significantly left for smaller mobile portrait */
  }

  @media (max-width: 414px) {
    width: 110%; 
    margin: -20px -55px; /* Shift left more for iPhone Plump portrait */
  }

  @media (max-width: 375px) {
    width: 110%; 
    margin: -50px -75px; /* Shift left more for iPhone portrait */
  }

  @media (max-width: 734px) and (orientation: landscape) {
    width: 110%; 
    margin: -70px -100px; /* Shift significantly left for mobile landscape */
  }

  @media (min-width: 1024px) and (orientation: landscape) {
    width: 110%; 
    margin: -100px -120px; /* Shift even more left for iPad landscape */
  }
`;



const WelcomeText = styled.h1`
  font-family: 'Arial', sans-serif;
  color: #2F9ABF;

  @media (max-width: 768px) {
    font-size: 1.5em; /* Responsive font size */
  }
`;

const AboutSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 40px;
  border-radius: 10px;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px; /* Adjusted padding */
  }
`;

const AboutTextContainer = styled.div`
  flex: 1;
  text-align: left;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 0; /* Removed right margin */
    margin-bottom: 20px; /* Added bottom margin */
  }
`;

const AboutTitle = styled.h2`
  font-family: 'Comic Sans MS', sans-serif;
  color: #2F9ABF;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5em; /* Responsive font size */
  }
`;

const AboutText = styled.p`
  font-family: 'Roboto', sans-serif;
  color: #2F9ABF;
  margin-bottom: 30px;
  font-size: 1.2em;

  @media (max-width: 768px) {
    font-size: 1em; /* Responsive font size */
  }
`;

const SatisfactionBars = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    align-items: center; /* Centering on smaller screens */
  }
`;

const BarContainer = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    text-align: center; /* Centering text on smaller screens */
  }
`;

const BarTitle = styled.h4`
  font-family: 'Roboto', sans-serif;
  color: #2F9ABF;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1em; /* Responsive font size */
  }
`;

const Bar = styled.div`
  background-color: #ffd6e7;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 30px;
  margin-top: 10px;

  @media (max-width: 768px) {
    height: 25px; /* Adjusted height */
  }
`;

const Progress = styled.div`
  background-color: #ff85a2;
  width: ${({ width }) => width};
  height: 100%;
  transition: width 0.5s ease;
`;

const TeamSection = styled.section`
  background-color: #BEEEFF;
  padding: 40px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px; /* Adjusted padding */
  }
`;

const TeamTitle = styled.h2`
  font-family: 'Pacifico', cursive;
  color: #2F9ABF;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5em; /* Responsive font size */
  }
`;

const TeamContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column; /* Stack members vertically */
    align-items: center; /* Centering members */
  }
`;

const TeamMember = styled.div`
  background-color: #BEEEFF;
  padding: 20px;
  border-radius: 10px;
  width: 45%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 80%; /* Responsive width */
    margin-bottom: 20px; /* Added bottom margin */
  }
`;

const MemberImage = styled.img`
  border-radius: 50%;
  width: 400px;
  height: 400px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 200px; /* Responsive width */
    height: 200px; /* Responsive height */
  }
`;

const MemberName = styled.h2`
  font-family: 'Roboto', sans-serif;
  color: #2F9ABF;

  @media (max-width: 768px) {
    font-size: 1.5em; /* Responsive font size */
  }
`;

const MemberRole = styled.p`
  font-family: 'Roboto', sans-serif;
  color: #2F9ABF;

  @media (max-width: 768px) {
    font-size: 1em; /* Responsive font size */
  }
`;

const TestimonialsSection = styled.section`
  background-color: #fff7f8;
  padding: 40px;
  border-radius: 10px;
  margin-top: 20px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 20px; /* Adjusted padding */
  }
`;

const TestimonialTitle = styled.h2`
  font-family: 'Comic Sans MS', sans-serif;
  color: #2F9ABF;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5em; /* Responsive font size */
  }
`;

const TestimonialContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Added gap to provide space between testimonials */

  @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
    padding: 20px;
    gap: 15px; /* Reduced gap on smaller screens */
  }
`;

const Testimonial = styled.div`
  background-color: #ffe6f2;
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0; /* Adjusted vertical margin */
  width: 90%;
  max-width: 900px;
  text-align: left;

  @media (max-width: 768px) {
    width: 65%; /* Full width on smaller screens */
    padding: 20px; /* Consistent padding */
  }
`;

const TestimonialText = styled.p`
  font-family: 'Roboto', sans-serif;
  color: #2F9ABF;
  font-size: 1.2em;

  @media (max-width: 768px) {
    font-size: 1em; /* Responsive font size */
  }
`;


const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const AuthorImage = styled.img`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const AuthorName = styled.h4`
  font-family: 'Roboto', sans-serif;
  color: #2F9ABF;
`;

const InstagramSection = styled.section`
  display: flex;
  align-items: center;
  padding: 40px;
  border-radius: 10px;
  margin-top: 20px;

  @media (max-width: 768px) {
    align-items: center;
    padding: 40px;
    border-radius: 10px;
    margin-top: 20px;
    padding: 20px; /* Adjusted padding */
    flex-direction: column;

  }
`;

const InstagramTitle = styled.h2`
  font-family: 'Pacifico', cursive;
  color: #2F9ABF;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5em; /* Responsive font size */
  }
`;

const InstagramFeed = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const InstagramImage = styled.img`
  width: 300px;
  height: 300px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    width: 90%; /* Full width on smaller screens */
    height: auto; /* Maintain aspect ratio */
  }
`;
const InstagramTextContainer = styled.div`
  flex: 1;
  max-width: 50%; /* Limit width for larger screens */
  margin-right: 20px;
  background: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px; /* Uniform gap between texts */

  @media (max-width: 768px) {
    max-width: 90%; /* Full width on smaller screens */
    margin-right: 0; /* Remove margin for better spacing */
  }
`;

const InstagramTagline = styled.p`
  font-family: 'Calibri', cursive; /* Font for tagline */
  color: #2F9ABF; /* Shade of blue */
  font-size: 2.3em; /* Larger size than the title */
  font-style: italic; /* Italics */
  margin: 0; /* Removed margin for uniform gap */

  @media (max-width: 768px) {
    font-size: 1.8em; /* Responsive font size */
  }
`;

const InstagramHandleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InstagramLogo = styled.img`
  width: 30px; 
  height: 30px;

  @media (max-width: 768px) {
    width: 25px; /* Smaller logo for mobile */
    height: 25px;
  }
`;

const InstagramHandle = styled.a`
  color: #2F9ABF; /* Adjust color as needed */
  text-decoration: none;
  font-family: 'Arial', sans-serif;
  font-size: 25px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 20px; /* Responsive font size */
  }
`;

const InstagramImages = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-content: flex-end;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust grid for smaller screens */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Stack images on very small screens */
  }
`;


const MapSection = styled.section`
  padding: 20px;
  background-color: #f0f8ff; /* Light background color for contrast */
  border-radius: 10px;
  margin-top: 20px;
`;

const MapTitle = styled.h2`
  font-family: 'Pacifico', cursive;
  color: #2F9ABF;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.8em; /* Responsive font size */
  }
`;

const Map = styled.iframe`
  width: 100%;
  height: 300px;
  border: 0;
  border-radius: 10px;
  margin-top: 20px;

  @media (max-width: 768px) {
    height: 200px; /* Smaller height on mobile */
  }
`;


const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <HomeContainer>
      <HeaderContainer>
        <BreadSliceContainer>
          <Logo src="./drooling tails logo.png" alt="Drooling Tails Logo" />
        </BreadSliceContainer>
        <WelcomeText>
          <h1>WELCOME TO DROOLING TAILS! </h1>
          <h3>The Best Part? It's Pooch Approved !!!!</h3>
          </WelcomeText>
      </HeaderContainer>
      <Carousel />
      <AboutSection>
        <AboutTextContainer>
          <AboutTitle>About the Bakery</AboutTitle>
          <AboutText>
            Drooling Tails Pet Bakery was started by our fussy eater Chef Nemo and his clumsy hooman in her 20s. 
            Here we make pretty and droolicious stuffs for your poochh 🍕🧁. 
          </AboutText>
        </AboutTextContainer>
        <SatisfactionBars>
          <BarContainer>
            <BarTitle>Cake Design</BarTitle>
            <Bar>
              <Progress width="98%" />
            </Bar>
          </BarContainer>
          <BarContainer>
            <BarTitle>Cake Recipe</BarTitle>
            <Bar>
              <Progress width="95%" />
            </Bar>
          </BarContainer>
          <BarContainer>
            <BarTitle>Pet Satisfaction</BarTitle>
            <Bar>
              <Progress width="100%" />
            </Bar>
          </BarContainer>
        </SatisfactionBars>
      </AboutSection>
      <TeamSection>
        <TeamTitle>Meet the Team</TeamTitle>
        <TeamContainer>
          <TeamMember>
            <MemberImage src="./nemo.jpeg" alt="Chef Nemo" />
            <MemberName>Chef Nemo</MemberName>
            <MemberRole>Head Baker</MemberRole>
          </TeamMember>
          <TeamMember>
            <MemberImage src="./hooman.jpeg" alt="Clumsy Hooman" />
            <MemberName>Clumsy Hooman(Saurabhi Deokar)</MemberName>
            <MemberRole>Assistant Baker</MemberRole>
          </TeamMember>
        </TeamContainer>
      </TeamSection>
      <TestimonialsSection>
        <TestimonialTitle>Testimonials</TestimonialTitle>
        <Slider {...settings}>
          <TestimonialContainer>
            <Testimonial>
              <TestimonialText>"My pets ( cat +dog) loved the cakes they baked. The communication and the delivery was really smooth and on time. Thank you so much for making the moment memorable with my pets. Will definitely recommend them."</TestimonialText>
              <Author>
                {/* <AuthorImage src="public/author1.jpg" alt="Author 1" /> */}
                <AuthorName>Suprativ Mukherjee</AuthorName>
              </Author>
            </Testimonial>
          </TestimonialContainer>
          <TestimonialContainer>
            <Testimonial>
              <TestimonialText>"Excellent cake in terms of design and flavour as my 10 year old baby girl lapped it all up. She is otherwise such a fussy eater and I doubted if she would like any cake l'd order, but the fact that she relished this cake well says so much"</TestimonialText>
              <Author>
                {/* <AuthorImage src="public/author2.jpg" alt="Author 2" /> */}
                <AuthorName>Sumdima Rai</AuthorName>
              </Author>
            </Testimonial>
          </TestimonialContainer>
          <TestimonialContainer>
            <Testimonial>
              <TestimonialText>"What an fantastic cake by drooling tails pet bakery visually stunning, taste awesome ...."Mickey"the pug loved it ... thank you Saurabhi"</TestimonialText>
              <Author>
                {/* <AuthorImage src="public/author3.jpg" alt="Author 3" /> */}
                <AuthorName>Herman Fernandez</AuthorName>
              </Author>
            </Testimonial>
          </TestimonialContainer>
        </Slider>
      </TestimonialsSection>
      <InstagramSection>
        <InstagramTextContainer>
          <InstagramTitle>FOLLOW US ON INSTAGRAM</InstagramTitle>
          <InstagramTagline>Sweet Moments are best saved memories!</InstagramTagline>
          <InstagramHandleContainer>
            <InstagramLogo src="/instagram-logo.png" alt="Instagram Logo" />
            <InstagramHandle href="https://www.instagram.com/droolingtails_petbakery" target="_blank" rel="noopener noreferrer">
              @droolingtails_petbakery
            </InstagramHandle>
          </InstagramHandleContainer>
        </InstagramTextContainer>
        <InstagramImages>
          <InstagramImage src="/p1.jpeg" alt="Instagram 1" column="1" row="1 / span 2" />
          <InstagramImage src="/p10.jpeg" alt="Instagram 2" column="2" row="1 / span 1" />
          <InstagramImage src="/p8.jpeg" alt="Instagram 3" column="3" row="1 / span 2" />
          <InstagramImage src="/p12.jpeg" alt="Instagram 4" column="1" row="2" />
          <InstagramImage src="/p16.jpeg" alt="Instagram 5" column="2" row="2" />
          <InstagramImage src="/p3.jpeg" alt="Instagram 6" column="3" row="2" />
        </InstagramImages>
      </InstagramSection>
      <MapSection>
      <MapTitle>Our Location</MapTitle>
      <Map
        src="https://www.google.com/maps/embed/v1/place?q=dahanukarvadi+kandivali+west&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
        allowFullScreen=""
        loading="lazy"
      ></Map>
    </MapSection>
    </HomeContainer>
  );
};

export default Home;