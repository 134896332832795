import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { auth } from './firebase';
import Cookies from 'js-cookie'; // Import js-cookie
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Register from './pages/Registration';
import Cart from './components/Cart';
import Checkout from './pages/Checkout';
import OrderConfirmation from './pages/OrderConfirmation'; // Ensure this import
import './App.css';

const AppContent = () => {
  const [user, setUser] = useState(null);
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    // Check for a cookie with user data
    const storedUser = Cookies.get('user');

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        // Store user data in a cookie
        Cookies.set('user', JSON.stringify(authUser), { expires: 7 }); // Cookie expires in 7 days
      } else {
        setUser(null);
        Cookies.remove('user'); // Remove the cookie when user logs out
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      Cookies.remove('user');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const addToCart = (product) => {
    const productWithQuantity = { ...product, quantity: 1 };
    setCartItems((prevCart) => [...prevCart, productWithQuantity]);
    console.log('Product added to cart:', productWithQuantity);
  };

  const updateQuantity = (index, newQuantity) => {
    setCartItems((prevCartItems) => {
      return prevCartItems.map((item, i) => {
        if (i === index) {
          return { ...item, quantity: Math.max(newQuantity, 1) };
        }
        return item;
      });
    });
  };

  const removeFromCart = (index) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
  };

  const clearCart = () => {
    setCartItems([]);
  };

  return (
    <div>
      <Header user={user} onLogout={handleLogout} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products addToCart={addToCart} user={user} />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/cart" element={<Cart cartItems={cartItems} updateQuantity={updateQuantity} removeFromCart={removeFromCart} user={user} />} />
        <Route path="/checkout" element={<Checkout cartItems={cartItems} clearCart={clearCart} user={user} />} />
        <Route path="/OrderConfirmation" element={<OrderConfirmation />} /> */}
      </Routes>
      <Footer />
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
