import React, { useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebase'; // Adjust the import path if needed
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';

const FooterContainer = styled.footer`
  background: url('/footer-bg.jpeg') no-repeat center center;
  background-size: cover;
  padding: 0px;
  text-align: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  color: #fff;

  @media (max-width: 768px) {
    background-position: top;
  }
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  border-top: 1px solid #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Section = styled.div`
  flex: 1;
  padding: 20px 30px;
  text-align: center;
  position: relative;

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    background-color: #333;
    height: 100%;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    &:not(:last-child)::after {
      display: none; // Remove dividers on smaller screens
    }
  }

  @media (max-width: 480px) {
    padding: 5px 10px;
  }
`;

const Title = styled.h3`
  color: #333;
  margin-bottom: 10px;
  font-weight: normal;
  font-family: 'Algerian', serif;
  font-size: 1.5em;

  @media (max-width: 768px) {
    font-size: 1.3em;
  }

  @media (max-width: 480px) {
    font-size: 1.1em;
  }
`;

const Text = styled.p`
  color: #333;
  margin: 5px 0;
  font-size: 1em;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }

  @media (max-width: 480px) {
    font-size: 0.8em;
  }
`;

const LogoImage = styled.img`
  max-width: 80px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    max-width: 70px;
  }

  @media (max-width: 480px) {
    max-width: 60px;
  }
`;

const SubscribeTitle = styled.h3`
  color: #333;
  margin-bottom: 10px;
  font-weight: normal;
  font-family: 'Algerian', serif;
  font-size: 1.5em;

  @media (max-width: 768px) {
    font-size: 1.3em;
  }

  @media (max-width: 480px) {
    font-size: 1.1em;
  }
`;

const SubscribeText = styled.p`
  color: #333;
  margin-bottom: 10px;
  font-size: 1em;

  @media (max-width: 768px) {
    font-size: 0.9em;
  }

  @media (max-width: 480px) {
    font-size: 0.8em;
  }
`;

const SubscribeForm = styled.form`
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 5px;
  }
`;

const PhoneInput = styled.input`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 250px;
  font-size: 16px;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 90%;
    font-size: 14px;
  }
`;

const SendButton = styled.button`
  padding: 10px 20px;
  background-color: #ff85a2;
  color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #ff6f8b;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 10px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const FooterLinks = styled.div`
  margin-top: 20px;
  color: #333;
  
  a {
    color: #333;
    margin: 0 10px;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
  }
`;

const BottomBar = styled.div`
  background-color: #ffabcc;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  border-top: 0px solid #333;
  position: relative;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    font-size: 12px;
    padding: 10px 15px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const Footer = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSubscribe = async (e) => {
    e.preventDefault();
  
    try {
      await addDoc(collection(db, 'subscribers'), {
        phoneNumber,
        timestamp: serverTimestamp(),
      });
      setMessage('Thank you for subscribing! You will receive updates soon.');
      setPhoneNumber('');
    } catch (error) {
      console.error('Error adding document: ', error.message);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <FooterContainer>
      <FooterContent>
        <Section>
          <Title>WORKING HOURS</Title>
          <Text>Monday - Friday: 12:00 pm - 08:00 pm</Text>
          <Text>Saturday: 12:00 pm - 08:00 pm</Text>
          <Text>Sunday: 12:00 pm - 08:00 pm</Text>
        </Section>
        <Section>
          <LogoImage src="./footerlogo.png" alt="Drooling Tails Pet Bakery Logo" />
          <Text>The Best Part, It is Pooch Approved!!</Text>
        </Section>
        <Section>
          <SubscribeTitle>SUBSCRIBE</SubscribeTitle>
          <SubscribeText>Get latest updates and offers.</SubscribeText>
          <SubscribeForm onSubmit={handleSubscribe}>
            <PhoneInput
              type="tel"
              placeholder="Enter your phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <SendButton type="submit">Send</SendButton>
          </SubscribeForm>
          {message && <Text>{message}</Text>}
        </Section>
      </FooterContent>
      <BottomBar>
        <Text>&copy; 2024 Drooling Tails Pet Bakery. All rights reserved.</Text>
        <FooterLinks>
          <a href="https://maps.app.goo.gl/rFjZfPZSe8xGwAoa8">Site Map</a>
        </FooterLinks>
      </BottomBar>
    </FooterContainer>
  );
};

export default Footer;
